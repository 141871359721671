<!--
 * @ Author: Zelmi Greyling
 * @ Create Time: 2021-10-21 10:04:30
 * @ Modified by: Zelmi Greyling
 * @ Modified time: 2022-04-28 12:22:44
 * @ Description: Fixer's news post management page in the admin dashboard section of the app.
 -->

<template>
    <v-row
        id="newsPostContainer"
        :style="$vuetify.breakpoint.mdAndUp ? 'max-height: calc(100vh - 108px);' : 'max-height: calc(100vh - 72px);'"
        style="overflow-y: auto;"
        justify="center"
    >
        <v-col
            cols="12"
            :style="$vuetify.breakpoint.lgAndUp ? 'max-width: 1440px;' : 'max-width: 960px;'"
        >
            <v-row
                style="position: relative;"
            >
                <!-- Add Button -->
                <v-btn
                    style="position: absolute; right: 16px; top: 10px;"
                    class="white--text no-uppercase rounded-lg os-13-sb"
                    color="#FFA858"
                    v-if="!showAddEditNewsPostBoolean"
                    @click="showAddEditNewsPost(true)"
                >
                    <span v-if="!$store.state.general.customBreakpoints.xxs">Add Post</span>
                    <v-icon
                        class="ml-1"
                    >
                        {{ mdiPlusCircleOutline }}
                    </v-icon>
                </v-btn>
                <!-- Add Button -->

                <v-col
                    cols="12"
                >
                    <span
                        class="os-22-sb"
                    >
                        Manage News <span v-if="!$store.state.general.customBreakpoints.xxs">Feed</span>
                    </span>
                </v-col>
            </v-row>

            <v-row>
                <!-- News Feed Cards -->
                <v-col
                    v-if="newsPosts === null || newsPosts.length === 0"
                    class="os-12-r text-center"
                    style="color: rgba(0, 0, 0, 0.38);"
                >
                    No news posts
                </v-col>

                <v-col
                    v-for="(item, index) in newsPosts"
                    :key="index"
                    cols="12"
                    v-else
                >
                    <v-card
                        class="rounded-lg mx-auto"
                        style="border: 1px solid #EFF4FA;"
                    >
                        <v-card-text
                            style="color: black; padding: 11px;"
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    class="pa-0"
                                    style="position: relative;"
                                >
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                icon
                                                v-bind="attrs"
                                                v-on="on"
                                                style="position: absolute; z-index: 1; right: 4px; top: 4px;"
                                            >
                                                <v-icon
                                                    color="#2E567A"
                                                >
                                                    {{ mdiDotsHorizontal }}
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item
                                                v-for="(menuItem, menuIndex) in menuItems"
                                                :key="menuIndex"
                                                @click="menuItemAction(menuItem, item)"
                                            >
                                                <v-list-item-title
                                                    class="os-12-sb"
                                                >
                                                    {{ menuItem }}
                                                </v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                    <div
                                        class="pa-3"
                                        style="min-height: 125px; display: flex; align-items: center;"
                                    >
                                        <v-img
                                            :src="item.image_url ? item.image_url : '/branding/FixerLogoBlue.png'"
                                            max-height="150px"
                                            max-width="150px"
                                            :height="$vuetify.breakpoint.mdAndUp && item.image_url ? '100px' : '75px'"
                                            :width="$vuetify.breakpoint.mdAndUp && item.image_url ? '100px' : '75px'"
                                            contain
                                            class="mx-auto"
                                        />
                                    </div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="os-16-sb"
                                    style="color: #2E567A;"
                                >
                                    {{ item.title }}
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="py-0"
                                >
                                    <v-divider />
                                </v-col>
                                <v-col
                                    cols="12"
                                    class="pb-0"
                                    style="color: #2E567A;"
                                    v-html="item.description"
                                />
                                <v-col
                                    class="text-right pt-0"
                                    cols="12"
                                    style="line-height: 1.5;"
                                >
                                    <div
                                        class="os-10-r"
                                        style="color: #8F9BB3;"
                                    >
                                        Expires:
                                    </div>
                                    <div
                                        class="os-12-sb"
                                        style="color: #2E567A;"
                                    >
                                        <span v-if="item.expiration_date">{{ item.expiration_date }}</span>
                                        <span
                                            v-else
                                            class="os-10-r"
                                            style="color: #8F9BB3;"
                                        >
                                            No expiration
                                        </span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <!-- News Feed Cards -->
            </v-row>

            <!-- Add View -->
            <!-- Desktop -->
            <v-row
                v-if="showAddEditNewsPostBoolean && $vuetify.breakpoint.mdAndUp"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="9"
                >
                    <v-row>
                        <!-- Heading -->
                        <v-col
                            cols="12"
                            class="os-22-sb"
                        >
                            <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> a News Post
                        </v-col>
                        <v-col
                            cols="7"
                            class="py-0"
                        >
                            <v-divider />
                        </v-col>
                        <!-- Heading -->

                        <!-- News Post Form -->
                        <v-col
                            cols="7"
                        >
                            <v-form
                                v-model="validNewsPostForm"
                                ref="newsPostForm"
                            >
                                <v-row>
                                    <v-col
                                        cols="12"
                                        class="pb-0 os-15-sb"
                                    >
                                        Post Title<span style="color: red;">*</span>
                                        <br />
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            placeholder="Post Title"
                                            :rules="shortNameRules"
                                            counter="50"
                                            v-model="newsPostToUpsert.title"
                                        />
                                    </v-col>

                                    <v-col
                                        cols="12"
                                        class="py-0 os-15-sb"
                                    >
                                        Post Description<span style="color: red;">*</span>
                                        <br />
                                        <general-tip-tap-text-editor-component
                                            class="pb-6"
                                            @textChanged="descriptionChanged"
                                            :text="newsPostToUpsert.description"
                                            :placeholder="'Post description...'"
                                        />
                                        <div
                                            v-if="descriptionTooLong"
                                            style="color: red;"
                                            class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                        >
                                            Max 180 characters
                                        </div>
                                        <div
                                            v-else-if="!validDescription"
                                            style="color: red;"
                                            class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                        >
                                            Required field
                                        </div>
                                    </v-col>

                                    <v-col
                                        cols="12"
                                    >
                                        <v-menu
                                            v-model="expirationDateMenu"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    dense
                                                    outlined
                                                    color="#2E567A"
                                                    class="pt-2 os-13-r"
                                                    background-color="white"
                                                    v-model="newsPostToUpsert.expiration_date"
                                                    :rules="expirationDateRules"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    :append-icon="mdiClose"
                                                    @click:append="newsPostToUpsert.expiration_date = null"
                                                />
                                            </template>
                                            <v-date-picker
                                                v-model="newsPostToUpsert.expiration_date"
                                                no-title
                                                scrollable
                                                color="#2E567A"
                                                @input="expirationDateMenu = false"
                                            />
                                        </v-menu>
                                    </v-col>  
                                </v-row>
                            </v-form>
                        </v-col>
                        <!-- News Post Form -->

                        <!-- Add/Edit/Cancel Buttons -->
                        <v-col>
                            <v-row
                                style="height: 100%;"
                                align="center"
                            >
                                <v-col
                                    cols="6"
                                    align-self="start"
                                    class="text-center"
                                >
                                    <input
                                        type="file"
                                        style="display: none"
                                        ref="banner"
                                        accept="image/*"
                                        @change="bannerSelected"
                                    >
                                    <div
                                        class="mb-2"
                                        style="min-height: 174px; display: flex; align-items: center;"
                                    >
                                        <v-img
                                            :src="banner ? banner : '/dashboard/general/DashboardGeneralUploadImage.png'"
                                            max-height="150px"
                                            max-width="150px"
                                            height="150px"
                                            width="150px"
                                            contain
                                            class="mx-auto"
                                        />
                                    </div>
                                    <div @click="selectBanner" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</div>
                                </v-col>
                                <v-col
                                    cols="12"
                                    align-self="end"
                                >
                                    <v-btn
                                        class="white--text no-uppercase rounded-lg os-13-sb"
                                        color="#FFA858"
                                        @click="upsertNewsPost(false)"
                                        :disabled="cannotSaveNewsPost"
                                    >
                                        <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> News Post
                                        <v-icon
                                            class="ml-1"
                                            v-if="action === 'add'"
                                        >
                                            {{ mdiPlusCircleOutline }}
                                        </v-icon>
                                        <v-icon
                                            class="ml-1"
                                            v-else-if="action === 'edit'"
                                        >
                                            {{ mdiCircleEditOutline }}
                                        </v-icon>
                                    </v-btn>
                                    <v-btn
                                        text
                                        class="no-uppercase rounded-lg os-13-sb"
                                        color="#8F9BB3"
                                        @click="showAddEditNewsPost(false)"
                                    >
                                        Cancel
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                        <!-- Add/Edit/Cancel Buttons -->
                    </v-row>
                </v-col>
            </v-row>
            <!-- Desktop -->

            <!-- Mobile -->
            <v-row
                v-if="showAddEditNewsPostBoolean && $vuetify.breakpoint.smAndDown"
                style="position: absolute; z-index: 1; top: 0; background-color: #F9FBFD; width: 100%; min-height: 100%;"
                class="mt-n1"
            >
                <v-col
                    cols="12"
                >
                    <v-form
                        v-model="validNewsPostForm"
                        ref="newsPostForm"
                    >
                        <v-row>
                            <!-- News Post Form -->
                            <v-col
                                cols="12"
                                class="os-22-sb"
                            >
                                <span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span> a News Post
                            </v-col>
                            <v-col
                                cols="12"
                                class="py-0"
                            >
                                <v-divider />
                            </v-col>
                            <v-col
                                cols="12"
                                class="text-center"
                            >
                                <input
                                    type="file"
                                    style="display: none"
                                    ref="banner"
                                    accept="image/*"
                                    @change="bannerSelected"
                                >
                                <div
                                    class="mb-2"
                                    style="min-height: 174px; display: flex; align-items: center;"
                                >
                                    <v-img
                                        :src="banner ? banner : '/dashboard/general/DashboardGeneralUploadImage.png'"
                                        max-height="75px"
                                        max-width="75px"
                                        height="75px"
                                        width="75px"
                                        contain
                                        class="mx-auto"
                                    />
                                </div>
                                <div @click="selectBanner" style="color: rgb(143, 155, 179); cursor: pointer;">Update Image</div>
                            </v-col>
                            <v-col
                                cols="12"
                                class="pb-0 os-15-sb"
                            >
                                Post Title<span style="color: red;">*</span>
                                <br />
                                <v-text-field
                                    dense
                                    outlined
                                    color="#2E567A"
                                    class="pt-2 os-13-r"
                                    background-color="white"
                                    placeholder="Post Title"
                                    :rules="shortNameRules"
                                    counter="50"
                                    v-model="newsPostToUpsert.title"
                                />
                            </v-col>

                            <v-col
                                cols="12"
                                class="py-0 os-15-sb"
                            >
                                Post Description<span style="color: red;">*</span>
                                <br />
                                <general-tip-tap-text-editor-component
                                    class="pb-6"
                                    @textChanged="descriptionChanged"
                                    :text="newsPostToUpsert.description"
                                    :placeholder="'Post description...'"
                                />
                                <div
                                    v-if="descriptionTooLong"
                                    style="color: red;"
                                    class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                >
                                    Max 180 characters
                                </div>
                                <div
                                    v-else-if="!validDescription"
                                    style="color: red;"
                                    class="os-12-r pl-3 pb-2 pt-1 mt-n6"
                                >
                                    Required field
                                </div>
                            </v-col>

                            <v-col
                                cols="6"
                            >
                                <v-menu
                                    v-model="expirationDateMenu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            outlined
                                            color="#2E567A"
                                            class="pt-2 os-13-r"
                                            background-color="white"
                                            v-model="newsPostToUpsert.expiration_date"
                                            :rules="expirationDateRules"
                                            v-bind="attrs"
                                            v-on="on"
                                            :append-icon="mdiClose"
                                            @click:append="newsPostToUpsert.expiration_date = null"
                                        />
                                    </template>
                                    <v-date-picker
                                        v-model="newsPostToUpsert.expiration_date"
                                        no-title
                                        scrollable
                                        color="#2E567A"
                                        @input="expirationDateMenu = false"
                                    />
                                </v-menu>
                            </v-col> 
                            <!-- News Post Form -->

                            <!-- Add/Edit/Cancel Buttons -->
                            <v-col
                                cols="6"
                                class="text-right pt-5"
                            >
                                <v-btn
                                    class="white--text no-uppercase rounded-lg os-13-sb"
                                    color="#FFA858"
                                    @click="upsertNewsPost(false)"
                                    :disabled="cannotSaveNewsPost"
                                >
                                    <span v-if="!$store.state.general.customBreakpoints.xxs"><span v-if="action === 'add'">Add</span><span v-else-if="action === 'edit'">Edit</span></span>
                                    <v-icon
                                        class="ml-1"
                                        v-if="action === 'add'"
                                    >
                                        {{ mdiPlusCircleOutline }}
                                    </v-icon>
                                    <v-icon
                                        class="ml-1"
                                        v-else-if="action === 'edit'"
                                    >
                                        {{ mdiCircleEditOutline }}
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    text
                                    class="no-uppercase rounded-lg os-13-sb"
                                    color="#8F9BB3"
                                    @click="showAddEditNewsPost(false)"
                                >
                                    Cancel
                                </v-btn>
                            </v-col>
                            <!-- Add/Edit/Cancel Buttons -->
                        </v-row>
                    </v-form>
                </v-col>
            </v-row>
            <!-- Mobile -->
            <!-- Add View -->
        </v-col>

        <!-- Confirm Delete Overlay -->
        <general-confirm-delete-overlay-component
            :showDeleteConfirmationOverlayBoolean="showDeleteConfirmationOverlayBoolean"
            :itemToDeleteType="'News Post'"
            @showDeleteConfirmationOverlay="showDeleteConfirmationOverlay"
        />
        <!-- Confirm Delete Overlay -->
    </v-row>
</template>
<script>
    import { NewsPostController } from '@/logic/controller/index.controller.js'
    import { mdiClose, mdiPlusCircleOutline, mdiArrowRightThinCircleOutline, mdiTrashCanOutline, mdiCircleEditOutline, mdiDotsHorizontal } from '@mdi/js'
    import GeneralTipTapTextEditorComponent from '@/components/general/tiptap/GeneralTipTapTextEditorComponent.vue'
    import GeneralConfirmDeleteOverlayComponent from '@/overlays/general/GeneralConfirmDeleteOverlayComponent.vue'

    export default {
        name: 'AdminNewsFeedPage',

        components: {
            GeneralTipTapTextEditorComponent,
            GeneralConfirmDeleteOverlayComponent
        },

        computed: {
            noResultsText () {
                return 'No results found for: "' + this.search + '"'
            },

            noDataText () {
                return 'No news posts added'
            },

            shortNameRules () {
                if (this.newsPostToUpsert.title) {
                    return [
                        v => !!v || 'Required field',
                        v => v.replace(/\s/g, '') !== '' || 'Required field',
                        v => v.length <= 50 || 'Max 50 characters'
                    ]
                } else return [ 'Required field' ]
            },

            expirationDateRules () {
                let today = new Date()
                today = today.toISOString().split('T')[0]

                if (this.newsPostToUpsert.expiration_date === null) return []
                else if (this.newsPostToUpsert.expiration_date.replace(/\s/g, '') === '') return [ 'Invalid date' ]
                else if (new Date(this.newsPostToUpsert.expiration_date.replace(/-/g, '/')).getTime() < new Date(today.replace(/-/g, '/')).getTime()) return [ 'Cannot be before today' ]
                else return []
            },

            cannotSaveNewsPost () {
                if (!this.newsPostToUpsert.description || this.descriptionTooLong) return true
                else return !this.validNewsPostForm
            }
        },

        data () {
            return {
                newsPostController: null,
                mdiClose,
                mdiPlusCircleOutline,
                mdiArrowRightThinCircleOutline,
                mdiTrashCanOutline,
                mdiCircleEditOutline,
                mdiDotsHorizontal,
                search: '',
                showAddEditNewsPostBoolean: false,
                validNewsPostForm: false,
                action: 'add',
                newsPostToUpsert: {
                    expiration_date: null,
                    description: null
                },
                newsPosts: [],
                menuItems: [
                    'Edit',
                    // 'Print',
                    'Delete'
                ],
                descriptionTooLong: false,
                validDescription: false,
                expirationDateMenu: false,
                bannerFormData: null,
                banner: null,
                showDeleteConfirmationOverlayBoolean: false,
                newsPostToDelete: null,
                lastPageLoaded: 0,
                totalPages: 0,
                isLoading: true
            }
        },

        beforeMount () {
            this.newsPostController = new NewsPostController()
        },

        async mounted () {
            await this.getNewsPosts()

            var self = this
        
            const newsPostContainer = document.getElementById('newsPostContainer')
            newsPostContainer.addEventListener('scroll', async () => {
                if (newsPostContainer.offsetHeight + newsPostContainer.scrollTop + 120 >= newsPostContainer.scrollHeight) {
                    if (!self.isLoading && self.lastPageLoaded < self.totalPages) {
                        await this.getNewsPosts()
                    }
                }
            })
        },

        methods: {
            async getNewsPosts () {
                try {
                    this.isLoading = true

                    this.lastPageLoaded = Number(this.lastPageLoaded) + 1

                    const recordsToRetrieve = 6

                    const response = await this.newsPostController.get(this.lastPageLoaded, recordsToRetrieve)

                    if (response && response.length > 0) {
                        this.totalPages = response[0].page_total
                        for (let index = 0; index < response.length; index++) {
                            const item = response[index]

                            if (item.expiration_date) {
                                const removeTime = item.expiration_date.split(' ')[0]
                                const convertDate = new Date(removeTime)
                                const splitConvertedDate = convertDate.toString().split(' ')
                                item.expiration_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] +  ' ' + splitConvertedDate[3]
                            }

                            this.newsPosts.push(item)
                        }
                    }

                    this.isLoading = false

                    this.$nextTick(async () => {
                        const newsPostContainer = document.getElementById('newsPostContainer')
                        if (response && response.length > 0 && this.lastPageLoaded < this.totalPages && newsPostContainer.offsetHeight + newsPostContainer.scrollTop === newsPostContainer.scrollHeight) await this.getNewsPosts()
                    })
                } catch (error) {
                    this.isLoading = false

                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async upsertNewsPost () {
                try {
                    const response = await this.newsPostController.upsert(this.newsPostToUpsert)
                    let newImage = false

                    if (this.bannerFormData) {
                        try {
                            const uploadResponse = await this.newsPostController.upsertBanner(response.id, this.bannerFormData)

                            this.banner = uploadResponse.image_url
                            this.bannerFormData = null
                            newImage = true
                        } catch (error) {
                            this.$store.commit('setSnackbar', {
                                text: error,
                                show: true
                            })
                        }
                    }

                    if (newImage) response.image_url = this.banner

                    if (response.expiration_date) {
                        const removeTime = response.expiration_date.split(' ')[0]
                        const convertDate = new Date(removeTime)
                        const splitConvertedDate = convertDate.toString().split(' ')
                        response.expiration_date = splitConvertedDate[2] + ' ' + splitConvertedDate[1] +  ' ' + splitConvertedDate[3]
                    }

                    if (this.action === 'edit') {
                        const newsPostIndex = this.newsPosts.findIndex(newsPostToFind => newsPostToFind.id === response.id)
                        if (newsPostIndex > -1) this.$set(this.newsPosts, newsPostIndex, response) // this.newsPosts[newsPostIndex] = response

                        this.$store.commit('setSnackbar', {
                            text: 'Updated successfully',
                            show: true,
                            color: '#069B34'
                        })
                    } else if (this.action === 'add' && (this.lastPageLoaded === this.totalPages || this.totalPages === 0)) {
                        this.newsPosts.unshift(response)
                    }

                    this.showAddEditNewsPostBoolean = false
                    this.banner = null
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            selectBanner () {
                this.$refs.banner.click()
            },

            bannerSelected (e) {
                const files = e.target.files

                if (files && files.length > 0) {
                    var reader = new FileReader()
                    var f = files[0]

                    var self = this

                    reader.onloadend = async function () {
                        self.banner = reader.result
                        self.bannerFormData = new FormData()
                        self.bannerFormData.append('image', files[0])
                    }

                    reader.readAsDataURL(f)
                }
            },

            descriptionChanged (text) {
                this.newsPostToUpsert.description = text

                var div = document.createElement('div')
                div.innerHTML = text
                var cleanText = div.textContent || div.innerText || ''

                /* if (cleanText.length > 180) this.descriptionTooLong = true
                else */ this.descriptionTooLong = false

                if (this.newsPostToUpsert.description === '<p></p>') {
                    this.newsPostToUpsert.description = null
                    this.validDescription = false
                } else if (this.descriptionTooLong) this.validDescription = false
                else this.validDescription = true
            },

            showAddEditNewsPost (value) {
                this.showAddEditNewsPostBoolean = value
                this.action = 'add'

                this.newsPostToUpsert = {
                    expiration_date: null,
                    description: null
                }
            },

            menuItemAction (action, item) {
                if (action === 'Edit') {
                    Object.assign(this.newsPostToUpsert, item)
                    this.banner = item.image_url

                    const dayjs = require('dayjs')
                    if (this.newsPostToUpsert.expiration_date) this.newsPostToUpsert.expiration_date = dayjs(this.newsPostToUpsert.expiration_date).format().split('T')[0]

                    this.action = 'edit'
                    this.showAddEditNewsPostBoolean = true
                } else if (action === 'Delete') {
                    this.confirmDeleteNewsPost(item)
                }
            },

            confirmDeleteNewsPost (newsPost) {
                this.newsPostToDelete = newsPost
                this.showDeleteConfirmationOverlay(true)
            },

            async deleteNewsPost (newsPost) {
                try {
                    await this.newsPostController.delete(newsPost)

                    this.$store.commit('setSnackbar', {
                        text: 'Deleted successfully',
                        show: true,
                        color: '#069B34'
                    })

                    const newsPostIndex = this.newsPosts.findIndex(newsPostToFind => newsPostToFind.id === newsPost.id)
                    if (newsPostIndex > -1) this.newsPosts.splice(newsPostIndex, 1)

                    this.newsPostToDelete = null

                    // this.lastPageLoaded = 0
                    // this.totalPages = 0
                    // this.newsPosts = []

                    // await this.getNewsPosts()
                } catch (error) {
                    this.$store.commit('setSnackbar', {
                        text: error,
                        show: true
                    })
                }
            },

            async showDeleteConfirmationOverlay (value, confirmDelete) {
                this.showDeleteConfirmationOverlayBoolean = value

                if (confirmDelete) await this.deleteNewsPost(this.newsPostToDelete)
            },
        }
    }
</script>
<style>
    @import url('../../../assets/style/style.fonts.css');

    .no-uppercase {
        text-transform: none !important;
        letter-spacing: 0px !important;
    }
</style>

<style scoped>
    >>> div.v-input__append-inner {
        margin-top: 5px !important;
    }
</style>
